import React, { useState, useEffect } from 'react';
import '../assets/SASS/home.scss';
import bannerImage1 from '../assets/images/banner.jpg';
import bannerImage2 from '../assets/images/ship.jpg';
import bannerImage3 from '../assets/images/freight.jpg';
import Services from './Services';
import ContactModal from './ContactModal';

const images = [bannerImage1, bannerImage2, bannerImage3];
const interval = 10000;

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);  
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);
  
    return () => {
      clearInterval(timer);
    };
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="pt-16 home">
      <div
        className="banner min-h-banner-height flex items-center"
        style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)),url('${images[currentImageIndex]}')` }}
      >
        <div className="text-white mx-auto w-9/12">
          <h1 className="text-my-orange font-bold mt-14">Global Network, Your Service</h1>
          <h1 className="pt-3 font-bold text-5xl">
            Safe Logistics
            <br />
            Solutions Provider
            <br />
            With Integrity
          </h1>
          <p className="pt-3">
            we have the capability to handle shipments of any size and complexity. 
            <br />
            Our commitment to excellence and customer satisfaction sets us
            <br />
            apart as a leader in the logistics industry in Ghana.
          </p>
          <button className="contact rounded mt-5 font-medium bg-my-orange px-4 py-2 mb-14" onClick={openModal}>Contact Us</button>
        </div>
      </div>

      <section className="mx-auto bg-my-gray lg:rounded-lg shadow-lg flex lg:my-16 flex-col lg:w-9/12 w-full p-12">
        <div className="w-full">
          <button className="bg-white px-2 py-0.5 border-l-4 border-my-orange">Top Services</button>
          <h1 className="mt-3 text-lg w-full font-extrabold">Safe & Reliable cargo solutions</h1>
        </div>
        <div className="mt-4 flex-col w-full">
          <div className="grid md:grid-cols-2 gap-3 w-full">
            <div className="flex md:h-service-card-height w-full bg-white rounded-xl p-4 shadow-lg"> 
              <div className="w-2/12 flex justify-center">
                <i class="mt-4 fa-solid fa-ship fa-2xl"></i>
              </div>
              <div className="w-10/12 px-3 border-s-2 border-my-gray">
                <h2 className="font-extrabold">Sea Transport Services</h2>
                <p>
                  Transports goods and cargo by sea, utilizing various types of vessels. This mode of transportation is known for its efficiency in handling large volumes of goods.
                </p>
              </div>
            </div>
            <div className="flex md:h-service-card-height bg-white rounded-xl p-4 shadow-lg">
              <div className="w-2/12 flex justify-center">
              <i class="mt-4 fa-solid fa-plane-departure fa-2xl"></i>
              </div>
              <div className="w-10/12 px-3 border-s-2 border-my-gray">
                <h2 className="font-extrabold">Air Freight Services</h2>
                <p>
                  Transports goods and cargo by aircraft, revolutionizing long-distance transportation with speed. It plays a critical role in global trade, connecting markets and industries.
                </p>
              </div>
            </div>
            <div className="flex md:h-service-card-height bg-white rounded-xl p-4 shadow-lg">
              <div className="w-2/12 flex justify-center">
              <i class="mt-4 fa-solid fa-warehouse fa-2xl"></i>
              </div>
              <div className="w-10/12 px-3 border-s-2 border-my-gray">
                <h2 className="font-extrabold">Warehousing Services</h2>
                <p>
                Provides storage facilities for goods and products, offering a range of services such as inventory management, order fulfillment, and distribution.
                </p>
              </div>
            </div>
            <div className="flex md:h-service-card-height bg-white rounded-xl p-4 shadow-lg">
              <div className="w-2/12 flex justify-center">
              <i class="mt-4 fa-solid fa-truck-fast fa-2xl"></i>
              </div>
              <div className="w-10/12 px-3 border-s-2 border-my-gray">
                <h2 className="font-extrabold">Local Shipping Services</h2>
                <p>
                Transports goods and cargo over land using trucks, trailers, and other vehicles. It is a flexible and versatile mode of transportation, offering door-to-door delivery and access to remote locations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Services />

      {showModal && <ContactModal onClose={closeModal} />}
    </div>
  )
};

export default Home;
