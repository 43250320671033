import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import gnaLogo from '../assets/images/gnalogo.png';

const NavBar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const redirectToTracking = () => {
    setIsDropdownOpen(false);
    window.location.href = 'https://www.dhl.com/gh-en/home/tracking.html';
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <nav className="flex justify-between items-center h-16 shadow-lg fixed bg-white w-full z-10 lg:px-14">
      <Link to="/" className="ms-8 lg:ms-0" onClick={scrollToTop}>
        <div>
          <img src={gnaLogo} alt="" srcset="" className=" lg:w-48 w-32" />
        </div>
      </Link>
      {isMobile ? (
        <button className="mr-8 text-2xl focus:outline-none" onClick={toggleDropdown}>
          {isDropdownOpen ? '✕' : '☰'}
        </button>
      ) : (
        <ul className="flex items-center mr-8 lg:mr-0 gap-12 w-fit h-fit">
          <li>
            <Link to="/" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Home</Link>
          </li>
          <li>
            <Link to="/about" onClick={(e) => { scrollToTop(); closeDropdown(); }}>About</Link>
          </li>
          <li>
            <Link to="/services" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Services</Link>
          </li>
          <li>
            <Link to="/Blog" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Blog</Link>
          </li>
          <li>
            <button onClick={redirectToTracking} className="text-white px-3 py-2 rounded-md bg-my-orange">Tracking</button>
          </li>
        </ul>
      )}
      {isDropdownOpen && isMobile && (
        <ul className="absolute top-16 right-0 bg-white shadow-lg rounded-es-lg rounded-ee-lg p-2 w-full">
          <li className="py-2 px-4">
            <Link to="/" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Home</Link>
          </li>
          <li className="py-2 px-4">
            <Link to="/about" onClick={(e) => { scrollToTop(); closeDropdown(); }}>About</Link>
          </li>
          <li className="py-2 px-4">
            <Link to="/services" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Services</Link>
          </li>
          <li className="py-2 px-4">
            <Link to="/Blog" onClick={(e) => { scrollToTop(); closeDropdown(); }}>Blog</Link>
          </li>
          <li className="py-2 px-4">
            <div className="text-white px-3 py-2 rounded-md bg-my-orange" onClick={(e) => { redirectToTracking(); closeDropdown(); }}>Tracking</div>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavBar;
