import React, { useState } from 'react';

function FAQ() {
  const [expandedItem, setExpandedItem] = useState(0);

  const faqs = [
    {
      question: 'Do we ship from the UK?',
      answer: 'Yes, we ship from multiple locations including the UK.',
    },
    {
      question: 'How long does shipping take?',
      answer: 'Shipping times vary depending on the destination. Please contact us for specific information.',
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept all major credit cards and PayPal.',
    },
    {
      question: 'Can I track my order?',
      answer: 'Yes, you can track your order using the tracking number provided.',
    },
    {
      question: 'Do you offer international shipping?',
      answer: 'Yes, we offer international shipping to most countries.',
    },
    {
      question: 'What is your return policy?',
      answer: 'Our return policy lasts 30 days. To be eligible for a return, your item must be unused and in the same condition that you received it.',
    },
    {
      question: 'How can I contact customer service?',
      answer: 'You can contact customer service by phone at +44 38 359 7444 or +233 (0)55 448 7416 or by email at gnaenterprises25@gmail.com',
    },
  ];

  const toggleItem = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  return (
    <>
      <div className="w-9/12 mx-auto pt-24 flex flex-col text-center">
        <h1 className="text-4xl font-bold">Frequently Asked Questions</h1>
        <p className="mt-2">
          Quick answers to question you may have about the company and our services. Can't find
          <br />
          what you're looking for? Contact Us or send a Quote.
        </p>
      </div>
      <div className="grid p-6 mb-24 mt-10 bg-my-gray-dark rounded-xl shadow-xl lg:grid-cols-2 grid-cols-1 w-9/12 gap-5 mx-auto">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`p-4 bg-white rounded-lg ${
              expandedItem === index ? 'row-span-2' : 'min-h-16'
            }`}
          >
            <h1 className="flex items-center cursor-pointer" onClick={() => toggleItem(index)}>
              <span className=" text-my-orange">{String(index + 1).padStart(2, '0')}.&nbsp;</span>
              {faq.question}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 ml-auto transform transition-transform duration-300 ${
                  expandedItem === index ? 'rotate-180' : ''
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </h1>
            {expandedItem === index && (
              <p className="mt-2 px-2 text-sm">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default FAQ;
