import React from 'react';

const ContactModal = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-8 max-w-md">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
        <p>
          ADDRESS :
          <br />
          Terema Street, opposite 2927 restaurant 
          Tesano - Accra
          Ghana 🇬🇭 
          <br />
          Acid Street, near ICGC TEMPLE Abease
          Amasaman - Accra
          Ghana 🇬🇭 
          <br /><br />
          PHONE:
          <br />
          +233 (0)55 448 7416
          <br />
          +44 38 359 7444
          <br />
          WhatsApp : +233 (0)55 448 7416
        </p>
        <p className="mt-3">
          EMAIL: gnaenterprises25@gmail.com
        </p>
        <div className="flex my-6 gap-3">
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
          <i class="fa-brands fa-square-x-twitter fa-xl"></i>
          </a>
          <a href="https://wa.me/233554487416" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-xl fa-square-whatsapp"></i>
          </a>
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-square-instagram fa-xl"></i>
          </a>
          <a href="https://www.instagram.com/gna_worldwide_logistics/?hl=en" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-square-facebook fa-xl"></i>
          </a>
        </div>
        <button className="bg-my-orange text-white px-4 py-2 rounded mt-4" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ContactModal;
