import React, { useState } from 'react';
import axios from 'axios';
import Footer from './Footer';
import '../assets/SASS/tracking.scss';
import Quote from './Quote';

const Tracking = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingResult, setTrackingResult] = useState(null);

  const handleTrackShipment = async (e) => {
    e.preventDefault();

    try {
      const options = {
        method: 'GET',
        url: 'https://api-test.dhl.com/track/shipments',
        params: { trackingNumber: trackingNumber },
        headers: { 'DHL-API-Key': 'demo-key' }, // Replace 'REPLACE_KEY_VALUE' with your actual DHL API key
      };

      const response = await axios.request(options);
      setTrackingResult(response.data);
    } catch (error) {
      console.error('Error fetching tracking information:', error);
    }
  };

  return (
    <div className="pt-16 w-full min-h-screen">
      <section className="bg-my-gray py-16">
        <div className="w-9/12 mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="flex justify-center py-3">
            <h1 className="text-3xl font-semibold">TRACK YOUR SHIPMENT</h1>
          </div>
          <div className="tracking p-4">
            <form className="p-4" onSubmit={handleTrackShipment}>
              <label htmlFor="trackingNumber" className="block text-white">
                Enter Tracking Number:
              </label>
              <input
                type="text"
                id="trackingNumber"
                name="trackingNumber"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                required
              />
              <button type="submit" className="mt-2 bg-my-orange text-white px-4 py-2 rounded-md">
                Track Shipment
              </button>
            </form>
          </div>
          {trackingResult && (
            <div className="p-6">
              <h2>Tracking result will be displayed here</h2>
              <h2 className="text-xl font-semibold">Tracking Result:</h2>
              <p>Carrier: {trackingResult.carrier}</p>
              <p>Status: {trackingResult.status}</p>
              <p>Location: {trackingResult.location}</p>
              <p>Estimated Delivery: {trackingResult.estimatedDelivery}</p>
            </div>
          )}
        </div>
      </section>
      <Quote />
      <Footer />
    </div>
  );
};

export default Tracking;
