import React from 'react';
import logo from '../assets/images/logo.png';
import '../assets/SASS/about.scss';
import Services from './Services';

const About = () => {
  return (
    <section className="pt-24 aboutus">
        <div className="flex flex-col lg:flex-row gap-7 w-9/12 lg:h-image-height mx-auto">
            <div className="lg:w-5/12 relative flex lg:flex-row gap-4 h-image-height">
                <div className="flex w-2/6 flex-col gap-4 h-full">
                  <div className="h-2/6 imageOne rounded-2xl">
                    <div className="absolute bg-my-orange p-6 rounded-2xl bottom-1/2 right-1/2">
                      <p className="text-center text-white">
                        <span className="text-3xl font-bold">8+</span>
                        <br />
                        Years of 
                        <br />
                        Experience
                      </p>
                    </div>
                  </div>
                  <div className="h-4/6 rounded-2xl imageTwo">
                    <p className="hidden">imageTwo</p>
                  </div>
                </div>
                <div className="h-full rounded-2xl w-4/6 imageThree">
                </div>
            </div>
            <div className="lg:w-7/12 flex flex-col justify-between">
              <div>
                <p className="text-4xl mt-2">
                  <span className="font-bold">
                    We Provide Logistics
                    <br /> 
                    Services
                  </span>
                  &nbsp;Focused On Quality
                </p>
                <div className="flex flex-col lg:flex-row w-full">
                  <div className="lg:w-1/2">
                    <p className=" text-sm mt-4 p-2">
                    Established in 2013, GNA Probiz Enterprise is a premier logistics company based in Ghana. With over four years of experience in the industry, we have built a reputation for providing reliable, efficient, and cost-effective logistics solutions to our clients. We understand the importance of timely and secure transportation of goods. Whether you are shipping locally or internationally, our team of dedicated professionals is committed to ensuring that your cargo reaches its destination safely and on time.
                  </p>  
                  </div>
                  <div className="lg:w-1/2">
                    <p className="text-sm mt-4 p-2">
                      Our range of services includes air freight, sea freight, road transportation, warehousing, and customs clearance. 
                      We work closely with our clients to understand their unique logistics needs and tailor our services to meet their requirements.
                      <br />
                      With a strong network of partners and agents worldwide, we have the capability to handle shipments of any size and complexity. Our commitment to excellence and customer satisfaction sets us apart as a leader in the logistics industry in Ghana.
                    </p>
                  </div>
                </div>      
              </div>
              {/* <div className="my-4 bg-my-orange text-white rounded-2xl shadow-lg h-20">
                Contact Us
              </div> */}
            </div>
        </div>
        <div className="w-9/12 flex-col mt-10 mx-auto pb-20 flex justify-center">
            <h1 className="text-2xl flex justify-center">Trusted By 50+ companies</h1>
            <div className="flex justify-center lg:flex-row mx-auto flex-col mt-10 gap-10">
                <img src={logo} className="w-44" alt="" srcset="" />
                <img src={logo} className="w-44" alt="" srcset="" />
                <img src={logo} className="w-44" alt="" srcset="" />
                <img src={logo} className="w-44" alt="" srcset="" />
            </div>
        </div>
        <Services />
    </section>
  )
};

export default About;
