import React, { useState } from 'react';
import '../assets/SASS/blog.scss';
import Footer from './Footer';
import Quote from './Quote';
import imageOne from '../assets/images/trucks.jpg';
import imageThree from '../assets/images/gallery/imageSix.jpg';
import logo from '../assets/images/gnalogo.png';
import imageFour from '../assets/images/gallery/imageFour.jpg';
import imageFive from '../assets/images/gallery/imageFive.jpg';
import imageSix from '../assets/images/gallery/imageTwo.jpg';
import imageEight from '../assets/images/gallery/imageThree.jpg';
import imageSeven from '../assets/images/gallery/imageOne.jpg';

const articleOne = `Shipping from the UK can be straightforward with the right know-how. Whether sending packages domestically or internationally, understanding the logistics is key to ensuring items arrive safely and on time.
<br /><br />
Domestic Shipping: Options like Royal Mail, DPD, UPS, and FedEx offer various services based on shipment size and urgency. Royal Mail provides standard, next-day, and tracked options for letters, parcels, and larger items. Courier services offer express and same-day delivery, ideal for businesses needing reliable service.
<br /><br />
International Shipping: For international shipping, consider customs regulations, shipping times, and costs. Couriers like DHL, UPS, and FedEx provide reliable international services, including express and economy options. Royal Mail also offers international services with varying tracking and delivery times.
<br /><br />
Customs and Regulations: Being aware of customs regulations is crucial. Provide detailed descriptions of items and pay any applicable duties or taxes. Couriers often handle customs clearance, but accuracy is essential to avoid delays or extra charges.
<br /><br />
Shipping Costs: Costs depend on package size, weight, and destination. Most couriers offer online calculators for estimates. Comparing prices and services can help find the best option.
<br /><br />
Packaging and Labeling: Proper packaging is vital for item protection. Use sturdy boxes or envelopes and fill empty spaces with packing material. Clearly label packages with recipient and return addresses.
<br /><br />
Tracking and Insurance: Tracking services allow monitoring of package progress. Insurance options protect against loss or damage during transit.
<br /><br />
Shipping from the UK offers reliable services for domestic and international shipments. Understanding logistics, customs regulations, and costs helps choose the right service for your needs, whether sending a letter, parcel, or freight shipment.`;

const articleTwo = `Logistics can often be a complex and daunting aspect of business operations, but with the right strategies, it can be simplified to improve efficiency and reduce costs. Simplifying logistics involves streamlining processes, optimizing routes, and leveraging technology to enhance visibility and control over shipments.
<br /><br />
One key strategy for simplifying logistics is to centralize operations and communication. By using a single platform or system to manage all logistics activities, businesses can eliminate the need for multiple tools and reduce the risk of errors. This centralization also improves visibility, allowing businesses to track shipments in real-time and make informed decisions to avoid delays or disruptions.
<br /><br />
Another way to simplify logistics is to optimize transportation routes. This can be achieved by using route planning software that takes into account factors such as traffic, weather, and delivery windows. By optimizing routes, businesses can reduce fuel costs, minimize vehicle wear and tear, and improve delivery times.
<br /><br />
Technology plays a crucial role in simplifying logistics. By implementing tracking and monitoring systems, businesses can easily track the location and status of their shipments. This not only improves visibility but also allows for proactive management of any issues that may arise.
<br /><br />
In conclusion, simplifying logistics is essential for businesses looking to improve efficiency and reduce costs. By centralizing operations, optimizing routes, and leveraging technology, businesses can streamline their logistics processes and ensure timely and cost-effective delivery of goods.`;

const articleThreeGhana = `Shipping goods to and within Ghana can be a complex but rewarding process for businesses and individuals alike. With the right understanding of the logistics involved, you can ensure your shipments arrive safely and on time.
<br />
<br />
When shipping to Ghana from overseas, it's crucial to be aware of the country's customs regulations. Providing accurate information about your items, including their value, is essential to avoid delays or additional charges. Using reputable couriers like DHL, UPS, and FedEx can help simplify the process, as they often handle customs clearance on your behalf.
<br />
<br />
Within Ghana, several options are available for shipping goods. Domestic courier services such as Ghana Post and local delivery companies offer reliable and cost-effective solutions for sending packages within the country. These services are ideal for businesses and individuals looking to transport goods quickly and efficiently.
<br />
<br />
For businesses looking to import goods into Ghana, understanding the importation process is key. Working with a freight forwarder can help you navigate the complexities of customs clearance and ensure your shipments comply with Ghanaian regulations.
<br />
<br />
Overall, navigating shipping to and within Ghana requires careful planning and attention to detail. By understanding the logistics involved and choosing the right shipping methods, you can ensure your shipments reach their destination safely and efficiently.`

const Blog = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState('');
  const [selectedContent, setSelectedContent] = useState('');

  const handleBlogClick = (blog, content) => {
    setSelectedBlog(blog);
    setSelectedContent(content);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <section className="pt-16 flex md:flex-row flex-col gap-4 w-full min-h-image-height">
        <div className="md:w-1/2 pe-4 md:pe-0 w-full md:py-4 pt-4 ps-4 mx-auto">
            <div className="bigImageBlog h-full rounded-xl">
              <div className="h-full gap-2 rounded-xl p-6 small-image-one-blog blog-one flex flex-col justify-end " onClick={() => handleBlogClick('Shipping from the UK: A Comprehensive Guide', articleOne)}>
                <button className="bg-my-orange px-2 text-white py-0.5 w-fit rounded-md">Shipping from the UK: A Comprehensive Guide</button>
                <p className="text-white font-semibold text-lg">
                  Shipping from the UK can be a straightforward process if you know what to expect and how to navigate the various options available. Whether you're sending packages ...... 
                </p>
                <div className="text-white flex gap-10">
                  <div className="flex text-white gap-2">
                    <i className="fa-solid fa-user"></i>
                    <p className=" font-semibold text-xs">GNA Probiz</p>
                  </div>
                  <div>
                      <p className="font-semibold text-xs">12/02/24</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="md:w-1/2 w-full mx-auto h-full md:py-4 pe-4 md:ps-0 ps-4 flex flex-col gap-4">
            <div className="h-1/2 blog-two">
                <div className="h-full gap-2 rounded-xl p-6 small-image-one-blog flex flex-col justify-end" onClick={() => handleBlogClick('Simplifying Logistics content', articleTwo)}>
                  <button className="bg-my-orange px-2 text-white py-0.5 w-fit rounded-md">
                    Simplifying Logistics:
                  </button>
                  <p className="text-white font-semibold text-lg">
                    Logistics can often be a complex and daunting aspect of business operations, but ...
                  </p>
                  <div className="text-white flex gap-10">
                    <div className="flex text-white gap-2">
                      <i className="fa-solid fa-user"></i>
                      <p className=" font-semibold text-xs">GNA Probiz</p>
                    </div>
                    <div>
                        <p className="font-semibold text-xs">12/02/24</p>
                    </div>
                  </div>
                </div>
            </div>
            <div className="h-1/2 blog-three">
                <div className="h-full rounded-xl small-image-two-blog" onClick={() => handleBlogClick('Navigating Shipping to and Within Ghana', articleThreeGhana)}>
                  <div className="h-full gap-2 rounded-xl p-6 small-image-one-blog flex flex-col justify-end">
                    <button className="bg-my-orange px-2 text-white py-0.5 w-fit rounded-md">
                      Navigating Shipping to and Within Ghana: A Practical Guide
                    </button>
                    <p className="text-white font-semibold text-lg">
                      Shipping goods to and within Ghana can be a complex but rewarding process for ...
                    </p>
                    <div className="text-white flex gap-10">
                      <div className="flex text-white gap-2">
                        <i className="fa-solid fa-user"></i>
                        <p className=" font-semibold text-xs">Samson</p>
                      </div>
                      <div>
                          <p className="font-semibold text-xs">12/02/24</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </section>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-10 w-full max-h-full overflow-y-auto">
            <button className="absolute top-6 right-12 bg-my-orange text-white px-2 py-1 rounded-full" onClick={closePopup}>Close</button>
            <h2 className="text-2xl font-bold mb-4">{selectedBlog}</h2>
            <div className="w-full">
              <div className="font-semibold text-sm" dangerouslySetInnerHTML={{ __html: selectedContent }} />
            </div>
          </div>
        </div>
      )}
      {/* <section className="gallery m-4 mb-16 p-4 border-2 rounded-lg border-my-gray">
        <h1 className=" font-medium text-2xl mb-3">Our gallery</h1>
        <div className="grid lg:grid-cols-4 grid-cols-1 gap-4">
          <div className="gallery-one lg:col-span-3 col-span-4 lg:row-span-2  overflow-hidden rounded-lg h-64">
            <img src={imageOne} className="lg:-translate-y-1/4" alt="" />
          </div>
          <div className="gallery-two lg:row-span-3 row-span-1 lg:col-span-1 col-span-4 overflow-hidden md:h-128 h-64 rounded-lg bg-my-gray">
            <img src={imageSeven} className="w-full md:h-full" alt="" srcset="" />
          </div>
          <div className="md:col-span-1 overflow-hidden col-span-4 gallery-three rounded-lg bg-my-gray h-64">
            <img src={imageThree} className="h-full w-full" alt="" srcset="" />
          </div>
          <div className="md:col-span-2 overflow-hidden col-span-4 gallery-four rounded-lg lg:col-span-2 h-64 flex">
            <img src={logo} className="flex mx-auto mt-auto mb-auto w-10/12" alt="" srcset="" />
          </div>
          <div className=" lg:col-span-1 md:col-span-2 col-span-4 gallery-five overflow-hidden rounded-lg bg-my-gray h-64">
            <img src={imageFour} className="h-full" alt="" srcset="" />
          </div>
          <div className=" lg:col-span-1 md:col-span-2 overflow-hidden col-span-4 gallery-six rounded-lg bg-my-gray h-64">
            <img src={imageFive} className="h-full" alt="" srcset="" />
          </div>
          <div className=" lg:col-span-1 md:col-span-2 col-span-4 gallery-seven overflow-hidden rounded-lg bg-my-gray h-64">
            <img src={imageSix} className=" rotate-45 scale-imageScale -translate-y-16" alt="" srcset="" />
          </div>
          <div className=" lg:col-span-1 md:col-span-2 col-span-4 gallery-eight rounded-lg bg-my-gray h-64 overflow-hidden">
            <img src={imageEight} className="h-64" alt="" srcset="" />
          </div>
        </div>
      </section> */}


      <section className="gallery m-4 mb-16 p-4 border-2 rounded-lg border-my-gray">
        <h1 className=" font-medium text-2xl mb-3">Our gallery</h1>

        <div className="flex flex-col gap-4">
          <div className="w-full flex gap-4 flex-col md:flex-col lg:flex-row">
            <div className="flex w-full lg:w-9/12 flex-col gap-4">
              <div className="overflow-hidden rounded-lg h-64 lg:w-full">
                <img src={imageOne} className="lg:-translate-y-1/4 md:-translate-y-48 md:w-full" alt="" />
              </div>
              <div className="w-full flex md:flex-row flex-col gap-4">
                <div className="overflow-hidden md:w-4/12 rounded-lg bg-my-gray h-64">
                  <img src={imageThree} className="h-full w-full" alt="" srcset="" />
                </div>
                <div className="md:w-8/12 overflow-hidden gallery-four rounded-lg h-64 flex">
                  <img src={logo} className="flex mx-auto mt-auto mb-auto w-10/12" alt="" srcset="" />
                </div>
              </div>
            </div>
            <div  className="overflow-hidden lg:w-3/12 md:w-full h-64 lg:h-blog-height rounded-lg bg-my-gray">
              <img src={imageSeven} className="lg:h-full md:w-full md:-translate-y-48 lg:-translate-y-0" alt="" srcset="" />
            </div>
          </div>


          <div className="flex flex-col md:flex-col lg:flex-row w-full gap-4">
            <div className="flex flex-col md:flex-row w-full gap-4">
              <div className="gallery-five md:w-1/2 overflow-hidden rounded-lg bg-my-gray h-64">
                <img src={imageFour} className="md:h-full w-full" alt="" srcset="" />
              </div>
              <div className=" overflow-hidden md:w-1/2 rounded-lg bg-my-gray h-64">
                <img src={imageFive} className="md:h-full w-full" alt="" srcset="" />
              </div>
            </div>
            <div className="flex flex-col md:flex-row gap-4 w-full">
              <div className="overflow-hidden md:w-6/12 rounded-lg bg-my-gray h-64">
                <img src={imageSix} className=" rotate-45 scale-imageScale -translate-y-16" alt="" srcset="" />
              </div>
              <div className=" rounded-lg md:w-6/12 bg-my-gray h-64 overflow-hidden">
                <img src={imageEight} className="md:h-64 w-full" alt="" srcset="" />
              </div>
            </div>
          </div>






        </div>
      </section>
      <Quote />
      <Footer />
    </div>
  );
};

export default Blog;
